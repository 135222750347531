<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-card class="text-center">
          <b-tabs
            nav-class="mb-3"
            pills
          >
            <b-tab
              active
              class="text-left"
              lazy
              title="Stages"
            >
              <!-- search input -->
              <div class="custom-search d-flex justify-content-start">
                <b-form-group
                  class="form-inline"
                  label="Search"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="stages.search"
                    class="d-inline-block mr-1"
                    placeholder="Search Stages"
                    type="text"
                  />
                  <b-button
                    v-b-modal.stageModal
                    variant="primary"
                  >
                    <feather-icon icon="PlusIcon" />
                    Add Stage
                  </b-button>
                </b-form-group>
              </div>

              <!-- table -->
              <b-overlay
                :show="$apollo.loading"
                spinner-variant="primary"
              >
                <vue-good-table
                  :columns="stages.columns"
                  :pagination-options="{
                    enabled: true,
                    perPage:stages.pageLength
                  }"
                  :rows="stagesRows"
                  :search-options="{
                    enabled: false,
                    externalQuery: stages.search }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >

                    <!--Column : Stage Action -->
                    <span v-if="props.column.field === 'action'">
                      <b-dropdown
                        dropright
                        no-caret
                        size="sm"
                        toggle-class="text-decoration-none"
                        variant="link"
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            class="text-body align-middle"
                            icon="MoreVerticalIcon"
                            size="16"
                          />
                        </template>
                        <b-dropdown-item @click="selectedStage = props.row.originalIndex; $bvModal.show('reviewModal')">
                          <feather-icon
                            class="mr-50"
                            icon="Edit2Icon"
                          />
                          <span>Review Form</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="selectedStage = props.row.originalIndex; $bvModal.show('evaluationModal')">
                          <feather-icon
                            class="mr-50"
                            icon="Edit2Icon"
                          />
                          <span>Evaluation Form</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                    <!--Column : Stage Action -->
                    <span v-else-if="props.column.field === 'forms'">
                      <b-button-group size="sm">
                        <b-button
                          variant="outline-primary"
                          :to="`/duediligence/review/${props.row.id}/1`"
                        >Review Form</b-button>
                        <b-button
                          variant="outline-primary"
                          :to="`/duediligence/evaluation/${props.row.id}/1/2`"
                        >Evaluation Form</b-button>
                      </b-button-group>
                    </span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select
                          v-model="stages.pageLength"
                          :options="['3','5','10']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                        <b-pagination
                          :per-page="stages.pageLength"
                          :total-rows="props.total"
                          :value="1"
                          align="right"
                          class="mt-1 mb-0"
                          first-number
                          last-number
                          next-class="next-item"
                          prev-class="prev-item"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-overlay>
              <b-modal
                id="stageModal"
                ok-title="Save"
                size="lg"
                title="Add Stage"
                no-close-on-backdrop
                @ok="addStage"
              >
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Stage ${parseInt(stagesRows[stagesRows.length - 1].stage_number) + 1}`"
                      label-for="stageName"
                    >
                      <b-form-input
                        v-model="stages.title"
                        placeholder="Stage Name"
                        type="text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Description"
                      label-for="description"
                    >
                      <b-form-textarea
                        id="description"
                        v-model="stages.description"
                        placeholder="Write max 50 word description"
                        rows="4"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-modal>
              <b-modal
                id="reviewModal"
                size="lg"
                title="Review Form"
                ok-title="Save"
                @ok="updateStageReview"
              >
                <!--          <form-builder-->
                <!--            v-model="rounds[selectedRound].applicationForm"-->
                <!--            :inherited-sections="sectionObject(rounds[selectedRound].programs_applicationsquestionstables, 'section')"-->
                <!--          />-->
                <form-builder
                  v-model="stagesRows[selectedStage].reviewForm"
                  :inherited-sections="sectionObject(stagesRows[selectedStage].duediligence_startupassessmenttables, 'section')"
                />
              </b-modal>
              <b-modal
                id="evaluationModal"
                size="lg"
                title="Evaluation Form"
                ok-title="Save"
                @ok="updateStageEvaluation"
              >
                <form-builder
                  v-model="stagesRows[selectedStage].evaluationForm"
                  :inherited-sections="sectionObject(stagesRows[selectedStage].duediligence_partnerassessmenttables, 'section')"
                />
              </b-modal>
            </b-tab>
            <b-tab
              class="text-left"
              lazy
              title="Partners"
            >
              <!-- search input -->
              <div class="custom-search d-flex justify-content-start">
                <b-form-group
                  class="form-inline"
                  label="Search"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="partners.search"
                    class="d-inline-block mr-1"
                    placeholder="Search Partners"
                    type="text"
                  />
                  <b-button
                    v-b-modal.partnerModal
                    variant="primary"
                  >
                    <feather-icon icon="PlusIcon" />
                    Add Partner
                  </b-button>
                </b-form-group>
              </div>
              <!-- table -->
              <b-overlay
                :show="$apollo.loading"
                spinner-variant="primary"
              >
                <vue-good-table
                  :columns="partners.columns"
                  :pagination-options="{
                    enabled: true,
                    perPage:partners.pageLength
                  }"
                  :rows="partnersRows"
                  :search-options="{
                    enabled: false,
                    externalQuery: partners.search }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >

                    <!--Column : Stage Action -->
                    <span v-if="props.column.field === 'action'">
                      <span>
                        <b-dropdown
                          dropright
                          no-caret
                          size="sm"
                          toggle-class="text-decoration-none"
                          variant="link"
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              class="text-body align-middle"
                              icon="MoreVerticalIcon"
                              size="16"
                            />
                          </template>
                          <b-dropdown-item @click="partners.selected.id = props.row.id; $bvModal.show('assignStartupModal')">
                            <feather-icon
                              class="mr-50"
                              icon="BoxIcon"
                            />
                            <span>Assign Startups</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="partners.selected.id = props.row.id; $bvModal.show('assignStageModal')">
                            <feather-icon
                              class="mr-50"
                              icon="LayersIcon"
                            />
                            <span>Assign Stages</span>
                          </b-dropdown-item>
                          <b-dropdown-item variant="danger">
                            <feather-icon
                              class="mr-50"
                              icon="TrashIcon"
                            />
                            <span>Delete</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select
                          v-model="partners.pageLength"
                          :options="['3','5','10']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                        <b-pagination
                          :per-page="partners.pageLength"
                          :total-rows="props.total"
                          :value="1"
                          align="right"
                          class="mt-1 mb-0"
                          first-number
                          last-number
                          next-class="next-item"
                          prev-class="prev-item"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-overlay>
              <b-modal
                id="partnerModal"
                ok-title="Save"
                title="Assign Mentors"
                no-close-on-backdrop
                @ok="addPartner"
              >
                <b-form-group
                  class="mb-2"
                  label="Select Partner"
                  label-for="partnerSelect"
                >
                  <v-select
                    id="partnerSelect"
                    v-model="partners.selected.id"
                    :options="networkList"
                    label="name"
                    :reduce="option => option.id"
                    placeholder="Select Partner"
                  />
                </b-form-group>
                <b-form-group
                  label="Designation"
                  label-for="partnerDesignation"
                >
                  <b-form-input
                    id="partnerDesignation"
                    v-model="partners.selected.designation"
                    placeholder="Designation of partner"
                    type="text"
                  />
                </b-form-group>
              </b-modal>
              <b-modal
                id="assignStageModal"
                ok-title="Save"
                title="Assign Stage"
                no-close-on-backdrop
                @ok="assignStage"
              >
                <b-row>
                  <b-col><b-form-group
                    class="mb-2"
                    label="Select Stage"
                    label-for="assignStage"
                  >
                    <v-select
                      id="assignStage"
                      v-model="stages.selected.id"
                      :options="stagesRows"
                      label="title"
                      :reduce="option => option.id"
                      placeholder="Select Stage"
                    />
                  </b-form-group></b-col>
                  <b-col><b-form-group
                    class="mb-2"
                    label="Select Startup"
                    label-for="assignStartup"
                  >
                    <v-select
                      id="assignStartup"
                      v-model="startups.selected.id"
                      :options="startupsRows"
                      :get-option-label="option => option.startups_basicinfo.title"
                      :reduce="option => option.id"
                      placeholder="Select Startup"
                    />
                  </b-form-group></b-col></b-row>
                <b-row>
                  <b-col>
                    <b-row><b-col cols="auto">
                      <h6 class="mt-25">
                        Assignments
                      </h6>
                    </b-col><b-col><hr></b-col></b-row><vue-good-table
                      :columns="partners.assignmentStageColumns"
                      :rows="partnerStageAssignments"
                    /></b-col>
                </b-row>
              </b-modal>
              <b-modal
                id="assignStartupModal"
                ok-title="Save"
                title="Assign Startup"
                no-close-on-backdrop
                @ok="assignStartup"
              >
                <b-row>
                  <b-col><b-form-group
                    class="mb-2"
                    label="Select Startup"
                    label-for="assignStartup2"
                  >
                    <v-select
                      id="assignStartup2"
                      v-model="startups.selected.id"
                      :options="startupsRows"
                      :get-option-label="option => option.startups_basicinfo.title"
                      :reduce="option => option.id"
                      placeholder="Select Startup"
                    />
                  </b-form-group></b-col></b-row>
                <b-row>
                  <b-col>
                    <b-row><b-col cols="auto">
                      <h6 class="mt-25">
                        Assignments
                      </h6>
                    </b-col><b-col><hr></b-col></b-row><vue-good-table
                      :columns="partners.assignmentStartupColumns"
                      :rows="partnerStartupAssignments"
                    /></b-col>
                </b-row>
              </b-modal>
            </b-tab>
            <b-tab
              class="text-left"
              lazy
              title="Startups"
            >
              <!-- search input -->
              <div class="custom-search d-flex justify-content-start">
                <b-form-group
                  class="form-inline"
                  label="Search"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="startups.search"
                    class="d-inline-block mr-1"
                    placeholder="Search Partners"
                    type="text"
                  />
                  <b-button
                    v-b-modal.startupModal
                    variant="primary"
                  >
                    <feather-icon icon="PlusIcon" />
                    Add Startup
                  </b-button>
                </b-form-group>
              </div>
              <!-- table -->
              <b-overlay
                :show="$apollo.loading"
                spinner-variant="primary"
              >
                <vue-good-table
                  :columns="startups.columns"
                  :pagination-options="{
                    enabled: true,
                    perPage:startups.pageLength
                  }"
                  :rows="startupsRows"
                  :search-options="{
                    enabled: false,
                    externalQuery: startups.search }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >

                    <!--Column : Stage Action -->
                    <span v-if="props.column.field === 'action'">
                      <span>
                        <b-dropdown
                          dropright
                          no-caret
                          size="sm"
                          toggle-class="text-decoration-none"
                          variant="link"
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              class="text-body align-middle"
                              icon="MoreVerticalIcon"
                              size="16"
                            />
                          </template>
                          <b-dropdown-item @click="startups.selected.id = props.row.id; $bvModal.show('submissionsModal')">
                            <feather-icon
                              class="mr-50"
                              icon="FileTextIcon"
                            />
                            <span>View Submissions</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select
                          v-model="startups.pageLength"
                          :options="['3','5','10']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                        <b-pagination
                          :per-page="startups.pageLength"
                          :total-rows="props.total"
                          :value="1"
                          align="right"
                          class="mt-1 mb-0"
                          first-number
                          last-number
                          next-class="next-item"
                          prev-class="prev-item"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-overlay>
              <b-modal
                id="startupModal"
                ok-title="Save"
                title="Add Startup"
                no-close-on-backdrop
                @ok="addStartup"
              >
                <b-form-group
                  class="mb-2"
                  label="Select Partner"
                  label-for="startupSelect"
                >
                  <v-select
                    id="startupSelect"
                    v-model="startups.selected.id"
                    :options="startupList"
                    label="title"
                    :reduce="option => option.id"
                    placeholder="Select Startup"
                  />
                </b-form-group>
              </b-modal>
              <b-modal
                id="submissionsModal"
                ok-only
                title="View Submissions"
                size="lg"
                no-close-on-backdrop
              >
                <b-form-group
                  description="Select Partner to view Evaluation Form"
                  label="Select Partner"
                  label-for="partnerSelect2"
                  class="mb-2"
                >
                  <v-select
                    id="partnerSelect2"
                    v-model="partners.selected.id"
                    :options="partnersRows"
                    :get-option-label="option => option.networks_basicinfo.name"
                    :reduce="option => option.id"
                    placeholder="Select Partner"
                  />
                </b-form-group>
                <!-- table -->
                <vue-good-table
                  :columns="stages.columns"
                  :pagination-options="{
                    enabled: true,
                    perPage:stages.pageLength
                  }"
                  :rows="stagesRows"
                  :search-options="{
                    enabled: false,
                    externalQuery: stages.search }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >

                    <!--Column : Stage Action -->
                    <span v-if="props.column.field === 'action'">
                      <b-dropdown
                        dropright
                        no-caret
                        size="sm"
                        toggle-class="text-decoration-none"
                        variant="link"
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            class="text-body align-middle"
                            icon="MoreVerticalIcon"
                            size="16"
                          />
                        </template>
                        <b-dropdown-item @click="selectedRound = 1;">
                          <feather-icon
                            class="mr-50"
                            icon="CheckSquareIcon"
                          />
                          <span>Update Progress</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>

                    <!--Column : Stage Action -->
                    <span v-else-if="props.column.field === 'forms'">
                      <b-button-group size="sm">
                        <b-button
                          variant="outline-primary"
                          :to="`/duediligence/review/${props.row.id}/${startups.selected.id}`"
                        >Review Form</b-button>
                        <b-button
                          v-if="partners.selected.id"
                          variant="outline-primary"
                          :to="`/duediligence/evaluation/${props.row.id}/${startups.selected.id}/${partners.selected.id}`"
                        >Evaluation Form</b-button>
                      </b-button-group>
                    </span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select
                          v-model="stages.pageLength"
                          :options="['3','5','10']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                        <b-pagination
                          :per-page="stages.pageLength"
                          :total-rows="props.total"
                          :value="1"
                          align="right"
                          class="mt-1 mb-0"
                          first-number
                          last-number
                          next-class="next-item"
                          prev-class="prev-item"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-modal>
            </b-tab>
          </b-tabs>
        </b-card>

      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton, BButtonGroup,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { reactive } from '@vue/composition-api'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import gql from 'graphql-tag'
import FormBuilder from '@/views/duediligence/components/FormBuilder.vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    FormBuilder,
    BModal,
    BOverlay,
    BFormTextarea,
    BButtonGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stagesRows: [
        {
          stage_number: 0,
          reviewForm: [],
          evaluationForm: [],
        },
      ],
      partnersRows: [],
      startupsRows: [],
      rounds: [
        { title: '' },
      ],
      selectedStage: 0,
    }
  },
  setup() {
    const stages = reactive({
      selected: {
        id: null,
      },
      title: '',
      description: '',
      pageLength: 5,
      search: null,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Stage #',
          field: 'stage_number',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Forms',
          field: 'forms',
        },
      ],
    })
    const partners = reactive({
      selected: {
        id: null,
        designation: null,
      },
      pageLength: 5,
      search: null,
      filter: {
        status: 'Current',
      },
      assignmentStageColumns: [{
        label: 'Startup',
        field: 'startup',
      },
      {
        label: 'Stage',
        field: 'stage',
      }],
      assignmentStartupColumns: [{
        label: 'Startup',
        field: 'startup',
      }],
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Name',
          field(row) {
            if (!row.networks_basicinfo) return '-'
            return row.networks_basicinfo.name || '-'
          },
        },
        {
          label: 'Designation',
          field: 'designation',
        },
      ],
    })
    const startups = reactive({
      selected: {
        id: null,
      },
      pageLength: 5,
      search: null,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Startup',
          field(row) {
            if (!row.startups_basicinfo) return '-'
            return row.startups_basicinfo.title || '-'
          },
        },
        {
          label: 'Current Stage',
          field(row) {
            if (!row.duediligence_stagetable) return '-'
            return row.duediligence_stagetable.title || '-'
          },
        },
        {
          label: 'Decision',
          field: 'decision',
        },
        {
          label: 'Status',
          field: 'status',
        },
      ],
    })
    return {
      stages,
      partners,
      startups,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    partnerStageAssignments() {
      if (!this.partners.selected.id) return []
      const arr = []
      this.partnersRows.find(obj => obj.id === this.partners.selected.id).duediligence_stageassignmenttables.forEach(e => arr.push({
        stage: e.duediligence_stagetable.title,
        startup: e.duediligence_startupassignmenttable.startups_basicinfo.title,
      }))
      return arr
    },
    partnerStartupAssignments() {
      if (!this.partners.selected.id) return []
      const arr = []
      this.partnersRows.find(obj => obj.id === this.partners.selected.id).duediligence_partnerassignments.forEach(e => arr.push({
        startup: e.duediligence_startupassignmenttable.startups_basicinfo.title,
      }))
      return arr
    },
  },
  methods: {
    linearizeFormBuilder(form) {
      const obj = []
      form.forEach(e => e.items.forEach(f => obj.push({
        stage_id: this.stagesRows[this.selectedStage].id,
        section: e.title || null,
        question: f.question || null,
        data_type: f.data_type || 'Short text',
      })))
      return obj
    },
    sectionObject(array, key) {
      if (!array) return []
      const arr = Array.from(array)
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash
          return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
      const obj = []
      Object.entries(arr)
        .forEach(([k, v]) => obj.push({
          title: k,
          items: v || null,
        }))
      return obj
    },
    addStage() {
      this.$apollo.mutate({
        mutation: gql`mutation (
          $title:String!,
          $description:String,
          $duediligence_id:Int,
          $stage_number:String,
        ) {
          insert_duediligence_stagetable(objects:[{
            title:$title,
            description:$description,
            stage_number:$stage_number,
            duediligence_id:$duediligence_id,
          }])
          {
            returning {
              id
            }
          }
        }`,
        variables: {
          title: this.stages.title,
          description: this.stages.description,
          duediligence_id: parseInt(this.$route.params.id, 10),
          stage_number: (this.stagesRows.length + 1).toString(),
        },
        update: () => {
          this.$apollo.queries.stagesRows.refetch()
        },
      })
    },
    updateStageReview() {
      this.$apollo.mutate(
        {
          mutation: gql`mutation($data: [duediligence_startupassessmenttable_insert_input!]!) {
                        delete_duediligence_startupassessmenttable(where: {stage_id: {_eq: ${this.stagesRows[this.selectedStage].id}}}) {
                          affected_rows
                        }
                        insert_duediligence_startupassessmenttable(objects: $data) {
                          affected_rows
                        }
                      }`,
          variables: {
            data: this.linearizeFormBuilder(this.stagesRows[this.selectedStage].reviewForm),
          },
          update: () => {
            this.$apollo.queries.stagesRows.refetch()
          },
        },
      )
      this.selectedStage = null
    },
    updateStageEvaluation() {
      this.$apollo.mutate(
        {
          mutation: gql`mutation($data: [duediligence_partnerassessmenttable_insert_input!]!) {
                        delete_duediligence_partnerassessmenttable(where: {stage_id: {_eq: ${this.stagesRows[this.selectedStage].id}}}) {
                          affected_rows
                        }
                        insert_duediligence_partnerassessmenttable(objects: $data) {
                          affected_rows
                        }
                      }`,
          variables: {
            data: this.linearizeFormBuilder(this.stagesRows[this.selectedStage].evaluationForm),
          },
          update: () => {
            this.$apollo.queries.stagesRows.refetch()
          },
        },
      )
      this.selectedStage = null
    },
    addPartner() {
      if (!this.partners.selected.id || !this.partners.selected.designation) return

      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_duediligence_partnertable_one(object: {partners_id: ${this.partners.selected.id}, designation: ${this.partners.selected.designation},  duediligence_id: ${this.$route.params.id}}) {
            id
          }
        }`,
        update: () => {
          this.partners.selected.id = null
          this.partners.selected.designation = null
          this.$apollo.queries.partnersRows.refetch()
        },
      })
    },
    // Assign Stage & Startup to Partner
    assignStage() {
      if (!this.partners.selected.id || !this.stages.selected.id || !this.startups.selected.id) return

      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_duediligence_stageassignmenttable_one(object: {partner_id: ${this.partners.selected.id}, startup_id: ${this.startups.selected.id}, stage_id: ${this.stages.selected.id}}) {
            id
          }
        }`,
        update: () => {
          this.partners.selected.id = null
          this.stages.selected.id = null
          this.startups.selected.id = null
          this.$apollo.queries.partnersRows.refetch()
        },
      })
    },
    // Assign Startup to Partner
    assignStartup() {
      if (!this.partners.selected.id || !this.startups.selected.id) return

      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_duediligence_partnerassignment_one(object: {duediligence_id: ${this.$route.params.id}, partner_id: ${this.partners.selected.id}, startup_id: ${this.startups.selected.id}}) {
            id
          }
        }`,
        update: () => {
          this.partners.selected.id = null
          this.startups.selected.id = null
          this.$apollo.queries.partnersRows.refetch()
        },
      })
    },
    addStartup() {
      if (!this.startups.selected.id) return

      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_duediligence_startupassignmenttable_one(object: {startup_id: ${this.startups.selected.id},  duediligence_id: ${this.$route.params.id}}) {
            id
          }
        }`,
        update: () => {
          this.startups.selected.id = null
          this.$apollo.queries.startupsRows.refetch()
        },
      })
    },
  },
  apollo: {
    stagesRows: {
      query() {
        return gql`
        {
          duediligence_basicinfo_by_pk(id: ${this.$route.params.id}) {
            duediligence_stagetables {
              id
              description
              title
              stage_number
              date_created
              duediligence_startupassessmenttables {
                id
                section
                question
                data_type
              }
              duediligence_partnerassessmenttables {
                id
                section
                question
                data_type
              }
            }
          }
        }`
      },
      update: data => data.duediligence_basicinfo_by_pk.duediligence_stagetables,
    },
    partnersRows: {
      query() {
        return gql`
        {
          duediligence_basicinfo_by_pk(id: ${this.$route.params.id}) {
            duediligence_partnertables {
              id
              designation
              networks_basicinfo {
                name
              }
              duediligence_stageassignmenttables(where: {duediligence_stagetable: {duediligence_id: {_eq: ${this.$route.params.id}}}}) {
                duediligence_stagetable {
                  title
                }
                duediligence_startupassignmenttable {
                  startups_basicinfo {
                    title
                  }
                }
              }
              duediligence_partnerassignments {
                duediligence_startupassignmenttable {
                  startups_basicinfo {
                    title
                  }
                }
              }
            }
          }
        }`
      },
      update: data => data.duediligence_basicinfo_by_pk.duediligence_partnertables,
    },
    startupsRows: {
      query() {
        return gql`
        {
          duediligence_basicinfo_by_pk(id: ${this.$route.params.id}) {
            duediligence_startupassignmenttables {
              id
              startups_basicinfo {
                title
              }
              duediligence_stagetable {
                title
              }
              decision
              status
            }
          }
        }`
      },
      update: data => data.duediligence_basicinfo_by_pk.duediligence_startupassignmenttables,
    },
    networkList: {
      query() {
        return gql`
        {
            networks_basicinfo {
              id
              name
            }
        }`
      },
      update: data => data.networks_basicinfo,
    },
    startupList: {
      query() {
        return gql`
        {
            startups_basicinfo {
              id
              title
            }
        }`
      },
      update: data => data.startups_basicinfo,
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
