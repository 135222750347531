var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{staticClass:"repeater-form",style:({height: _vm.trHeight}),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},_vm._l((_vm.sections),function(section,index){return _c('b-row',{key:section.id,staticClass:"mb-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":("Section " + (index+1)),"label-for":("section-" + (index+1))}},[_c('b-form-input',{attrs:{"id":("section-" + (index+1)),"type":"text","placeholder":"Section title","required":""},on:{"change":_vm.notifyParent},model:{value:(section.title),callback:function ($$v) {_vm.$set(section, "title", $$v)},expression:"section.title"}})],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[(index === _vm.sections.length - 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",class:{'mr-2': _vm.sections.length > 1 },attrs:{"variant":"primary"},on:{"click":function($event){_vm.sections.push({
            id:Math.random(),
            title: '',
            items: [{
              id: 1,
              question: '',
              data_type: '',
              prevHeight: 0,
            }],
          })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Section")])],1):_vm._e(),(_vm.sections.length > 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.sections.splice(index, 1)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Remove")])],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('transition-group',{attrs:{"name":"list"}},_vm._l((section.items),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,staticClass:"ml-25 w-100 border-left py-1 pr-1 bg-light rounded",attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"7"}},[_c('b-form-group',{attrs:{"label":("Question " + (index+1)),"label-for":'question-'+item.id}},[_c('b-form-input',{attrs:{"id":("question-" + (item.id)),"type":"text","placeholder":"Question","required":""},on:{"change":_vm.notifyParent},model:{value:(item.question),callback:function ($$v) {_vm.$set(item, "question", $$v)},expression:"item.question"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Data Type","label-for":("data_type-" + (item.id))}},[_c('v-select',{staticClass:"answer_type",attrs:{"id":("data_type-" + (item.id)),"options":['Short text',
                             'Long text',
                             'Drop Down',
                             'Number',
                             'Email',
                             'Website URL',
                             'Document' ],"label":"Data type","append-to-body":"","close-on-select":true,"placeholder":"Select from list"},on:{"change":_vm.notifyParent},model:{value:(item.data_type),callback:function ($$v) {_vm.$set(item, "data_type", $$v)},expression:"item.data_type"}})],1)],1),(section.items.length > 1)?_c('b-col',{staticClass:"mb-50",attrs:{"cols":"auto"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return section.items.splice(index, 1)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[(index === section.items.length - 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){section.items.push({
                id: Math.random(),
                question: '',
                data_type: '',
                prevHeight: 0,
              })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Question")])],1):_vm._e()],1)],1)}),1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }